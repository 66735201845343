import React, { useState, useEffect } from 'react';
import LocationLayout from '../../components/locationLayout';
import { StaticImage } from 'gatsby-plugin-image';
import toast, { Toaster } from 'react-hot-toast';

const Contact = () => {
  const [cart, setCart] = useState([]);
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    telephone: '',
    email: '',
    evenement: '',
    date: '',
    infos: '',
  });

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('/.netlify/functions/sendEmail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...formData, cart })
    });
    

    if (response.ok) {
      toast.success('Votre demande a été envoyée avec succès! 📧');
    } else {
      toast.error('Une erreur est survenue. Veuillez réessayer. ❌');
    }
  };

  return (
    <LocationLayout>
      
      <Toaster position="top-right" reverseOrder={false} />

      <div className="md:w-[1920px] w-full max-w-full mx-auto">
        <h1 className="text-3xl font-bold my-6 !mb-12">Formulaire</h1>
        <div className="location-home md:max-w-[1110px] max-w-full justify-center mx-auto">

          <center>
            <div className="top-image text-center w-full px-8">
              <StaticImage alt='Image de la scène du Festif!' className="md:max-w-[1856px] max-w-full max-h-[1044px]" src="../../images/location/contact.png" />
            </div>
          </center>

          <div className='md:w-1/2 w-full md:ml-9 md:mx-9 mx-0 my-9 px-9'>
            <h2 className='font-poppins font-bold uppercase'>Contactez-nous pour réserver</h2>

            {/* FORMULAIRE */}
            <form onSubmit={handleSubmit} className='md:w-4/5 w-full mx-0 space-y-4'>
                {[
                { label: 'Prénom', name: 'prenom', type: 'text', required: true },
                { label: 'Nom', name: 'nom', type: 'text', required: true },
                { label: 'Numéro de téléphone', name: 'telephone', type: 'tel', required: true },
                { label: 'Adresse courriel', name: 'email', type: 'email', required: true },
                { label: "Nom de l'évènement", name: 'evenement', type: 'text', required: true },
                { label: "Date de l'évènement", name: 'date', type: 'date', required: true },
                { label: 'Informations additionnelles', name: 'infos', type: 'textarea', required: false }
                ].map(({ label, name, type, required }) => (
                <div key={name} className="flex flex-col">
                    <label className="font-semibold mb-1" htmlFor={name}>{label} {required && '*'}</label>
                    {type === 'textarea' ? (
                    <textarea id={name} name={name} value={formData[name]} onChange={handleChange} 
                        className="w-full p-2 border border-black rounded-md bg-white" rows="4"></textarea>
                    ) : (
                    <input id={name} name={name} type={type} required={required} value={formData[name]} onChange={handleChange} 
                        className="w-full p-2 border border-black rounded-md bg-white" />
                    )}
                </div>
                ))}

                <p className='!italic !py-2'>Veuillez noter que votre panier sera automatiquement joint à votre demande de réservation. Le Festif! vous contactera sous peu pour discuter de votre évènement.</p>
                <button type="submit" className="">Soumettre</button>
            </form>
          </div>

        </div>
      </div>
    </LocationLayout>
  );
};

export default Contact;
